import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs";

const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/no/analyse-rådgivning",
  "Hendelsessporing": "/no/hendelsessporing"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/event-tracking"
);

const EventTracking = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Hendelsessporing i Google Analytics [Setup Guide ${currentYear}]`}
        description="Guide til å konfigurere hendelsessporing for moderne Google Analytics sammen med Google Tag Manager. Veiledning med skjermbilder og forklaringer fra sertifisert ekspert."
        lang="no"
        canonical="/no/hendelsessporing"
        image="google-analytics-event-tracking-report-hero"
        alternateLangs={alternateLangs}
        datePublished="2024-06-12T04:32:43.188Z"
        dateModified="2024-06-12T06:56:07Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report-hero.png"
          alt="hendelsesdata graf fra Google Analytics hendelsesrapportering"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Hendelsessporing</H>
        <p>
          Konfigurering av hendelsessporing i Google Analytics er avgjørende for dataanalyse, fordi det brukes til å
          spore brukerinteraksjoner. Analyse av sidevisninger, økter og besøkende gir kunnskap om engasjementet til en
          nettstedsbesøkende.
        </p>{" "}
        <p>
          Dette løses ved å samle inn tilleggsdata fra tilpassede hendelser som fanger opp brukerinteraksjoner, for
          eksempel lenkeklikk.
        </p>
        <p>
          Hendelser representerer brukerinteraksjoner eller forekomster.
          <br />
          Vanligvis står de viktigste interaksjonene i forhold til målet for nettstedet.
          <br /> Derfor setter vi opp tilpasset hendelsessporing for å bruke det som en ytelsesindikator for å se om våre
          mål blir oppnådd.
        </p>
        <p>
          Hendelser kan grupperes i <strong>harde konverteringer</strong> og <strong>myke konverteringer</strong> for å
          ta hensyn til deres forhold til nettstedets overordnede mål.
        </p>
        <p>
          Siden hendelser ofte er grunnlaget for å overvåke konverteringer i en e-handels- eller remarketing-kontekst,
          kalles det noen ganger konverteringssporing.
          <br /> Jeg skiller mellom konverteringssporing og sporing av en hendelse, siden en konvertering også kan være
          en sidevisning eller en <b>passiv</b> forekomst, for eksempel å tilbringe en viss tid på en landingsside.
        </p>
        <p>
          For å illustrere hva hendelsessporing betyr, vil jeg vise deg hvordan du setter opp <strong>klikksporing</strong> på en
          sende-knapp. Deretter bør du kunne sette opp dine egne unike klikkhendelser på hvilket som helst element du ønsker - så
          la oss komme i gang!
        </p>
        <H as="h2">Hva er hendelsessporing i Google Analytics?</H>
        <p>
          En hendelse kan enten være en <strong>aktiv brukerinteraksjon</strong> eller en <strong>passiv forekomst</strong>.
          Å spore dem betyr å overvåke eller telle forekomstene og sette dem i forhold til brukere, besøk og andre
          dimensjoner for senere analyse.
        </p>
        <p>Ettersom du står fritt til å konfigurere hendelser som du vil, kan de ta mange forskjellige former.</p>
        <H as="h3">Eksempler</H>
        <ul>
          <li>legge til et produkt i handlekurven</li>
          <li>registrere seg på en e-postliste</li>
          <li>bla ned til bunnen av en artikkel</li>
          <li>klikke på play i en video</li>
          <li>brukerinnlogging</li>
          <li>klikke på et bilde for å zoome</li>
          <li>innsending av et kontaktskjema</li>
          <li>klikk på utgående lenke</li>
          <li>skrive ut en artikkel</li>
          <li>laste ned en fil</li>
        </ul>
        <p>
          Som du kan se, er hendelser vanligvis knyttet til noe brukeren <em>gjør</em>, basert på overvåking av...
        </p>
        <ul>
          <li>museklikk</li>
          <li>musebevegelser</li>
          <li>rulling</li>
          <li>nettlesernavigasjon</li>
          <li>tastaturinndata</li>
        </ul>
        <p>
          Hendelser kan også være <em>passive</em>, uten at brukeren gjør noe aktivt.
          <br />
          For eksempel når brukeren bruker en viss <strong>tid på en side</strong> eller når et skjema returnerer en
          <strong>feil</strong>. Også når et <strong>chatbot-vindu</strong> åpnes for å snakke med besøkende,
          er det ingen aktivitet fra brukeren.
        </p>
        <p>Selv om en <b>sidevisning</b> teknisk sett også er en interaksjon, teller den mest brukte Universal Google Analytics ikke en sidevisning som en hendelse. Imidlertid bruker den nye Google Analytics App + Web (for tiden i Beta) en annen datamodell som også sporer sidebesøk som hendelser.</p>
        <H as="h2">Hendelsestyper</H>
        <p>Vi kan skille mellom to typer hendelser:</p>
        <H as="h6">Aktive hendelser (med brukerinteraksjon)</H>
        <ul>
          <li>museklikk, rullesporing og musebevegelser</li>
          <li>skjermberøring</li>
          <li>tastaturinndata</li>
        </ul>
        <H as="h6">Passive hendelser</H>
        <ul>
          <li>nå en forhåndsdefinert tidsgrense</li>
          <li>Server-responser</li>
          <li>Programmatisk validering</li>
          <li>Tredjepartsskript</li>
        </ul>
        <p>
          Merk at ved å spore aktive og passive hendelser kan du rekonstruere en stor del av
          kundereisen. <br />
          Slike data gjør det mulig å trekke konklusjoner om brukerens opplevelse fra begynnelse til slutt. Hvis du finner noen
          feil i reisen, kan du nå ta grep for å redusere de negative opplevelsene.
        </p>
        <H as="h2">Struktur av hendelser i Google Analytics</H>
        <p>
          Hver hendelse i Google Analytics har noen hendelsesfelt, eller biter av informasjon om hendelsen, som vi kan
          bruke til å beskrive forekomsten:
        </p>
        <ul>
          <li>
            <strong>hendelseskategori</strong> - for å kategorisere hendelser i grupper. I sammenheng med et kontaktskjema, for
            eksempel, vil du gruppere alle hendelser i en hendelseskategori <em>kontaktskjema</em>
          </li>
          <li>
            <strong>hendelseshandling</strong> - for å beskrive interaksjonen, f.eks. <em>skjemainnsending</em>
          </li>
          <li>
            <strong>hendelsesetikett</strong> - for å legge til tilleggsinformasjon om interaksjonen. For eksempel, hvis brukeren
            sender <em>adressen til nettstedet sitt</em> sammen med skjemaet, bruker du det for å skille mellom de mottatte skjemaene
          </li>
          <li>
            <strong>hendelsesverdi</strong> - (<em>valgfritt</em>) for å legge til en numerisk verdi til informasjonen. For
            eksempel, hvis i gjennomsnitt hver 25. kontakt fører til en konvertering på 100$, kunne vi tildele en verdi på <em>4</em>
            til hver hendelse.
          </li>
          <li>
            <strong>hendelse ikke-interaksjon</strong> - for å skille mellom en aktiv eller passiv brukerinteraksjon.
            <br />
            Som standard er den satt til <code>false</code>, noe som betyr at hendelsen regnes som aktiv.
            <strong>Viktig:</strong> Aktive hendelser påvirker beregningen av en sides <b> <Link to="/no/wiki-analytics/bounce-rate">fluktfrekvens</Link></b>, ved
            å senke den. Så hvis du noen gang sliter med en urealistisk lav fluktfrekvens, er det sannsynlig at noen hendelser
            brukerinteraksjon er satt til <code>true</code> ved en feil.
          </li>
        </ul>
        <H as="h2">Implementere hendelsessporing i Google Analytics</H>
        <p>Det er to mulige måter å implementere tilpasset hendelsessporing:</p>
        <ol>
          <li>
            <strong>Implementere det</strong> med javascript-kode på alle relevante HTML-elementer sammen
            med en onclick-hendelsesbehandler - <strong>Jeg anbefaler ikke</strong> denne tilnærmingen, siden den er feilutsatt
            og ikke skalerbar. Hvis du likevel ønsker å gjøre dette, kan du finne kodeeksempler i
            <strong>Googles dokumentasjon for hendelsessporing</strong>:
          </li>
        </ol>
        <ul>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/events" target="_blank">
              analytics.js dokumentasjon for hendelser (legacy)
            </a>
          </li>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/events" target="_blank">
              gtag.js dokumentasjon for hendelser
            </a>
          </li>
        </ul>
        <ol start="2">
          <li>
            <strong>Sette opp med Google Tag Manager</strong> eller et annet <Link to="/no/tagg-administrasjon">taghåndteringssystem</Link> etter eget valg. <br />
            Enkelhet og fleksibilitet ved implementering av endringer, samt skalerbarhet og enkel integrering med
            tredjepartstjenester gjør det til den foretrukne implementeringsmetoden.
          </li>
        </ol>
        <p>
          Jeg vil forklare hvordan du sporer hendelser med Google Tag Manager, siden det er det mest brukte taghåndteringssystemet som alle kan få tilgang til.
        </p>
        <p>Hvis du vil lære mer om GTM, som hvordan sette det opp og spore ting som utgående lenker og nedlastinger, les min <Link to="/no/google-tag-manager-oppsett">Google Tag Manager-veiledning</Link>.</p>
        <H as="h2">Veiledning for å spore hendelser med Google Tag Manager</H>
        <p>
          Å sette opp egendefinert hendelsessporing kan deles opp i å lage en <strong>utløser</strong> for hendelsen, samt lage en <strong>hendelsestagg</strong>, som fyller ut hendelsesegenskapene (
          <em>kategori, handling, etikett</em>, osv.). <br />
          Verdiene for hendelsesegenskapene kan enten tildeles statisk eller dynamisk - der sistnevnte er foretrukket for å skalere konfigurasjonen over større nettsteder.
        </p>
        <p>
          Som et eksempel skal vi implementere Google Analytics-sporing for et knappetrykk. <strong>Klikksporing</strong> er den mest brukte formen for brukeradferdssporing, så eksempelet bør være
          anvendelig for mange andre bruksområder.
        </p>
        <p>På dette tidspunktet antar jeg at du allerede har opprettet en sporings-ID og at sporingskoden ble implementert på hele nettstedet da du <Link to="/no/google-analytics-oppsett">satte opp Google Analytics</Link> med GTM.</p>

        <H as="h3">Konfigurer utløseren for hendelsen i Google Tag Manager</H>
        <ul>
          <li>
            Først må vi logge inn på vår <a href="https://tagmanager.google.com">Google Tag Manager container</a> og <strong>opprette en ny utløser</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-tracking-new-trigger.png"
          alt="Opprett ny utløser i Google Tag Manager"
          className="article-img"
        />
        <ul>
          <li>
            Gi utløseren et <strong>navn</strong>, for eksempel <strong>Klikk - Knapp</strong>, og klikk på <strong>utløserkonfigurasjon</strong>-feltet for å velge en utløsertype. Du vil se en liste over utløsertyper skyve inn fra høyre.
          </li>
          <li>
            Velg <strong>Alle elementer</strong> som type og velg at <strong>utløseren bare skal utløses ved noen klikk</strong>.
          </li>
          <li>
            Velg deretter <strong>Klikkelement</strong> i den første nedtrekksmenyen. Hvis det ikke er oppført, klikk <strong>Velg innebygd variabel...</strong> og aktiver det.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-variable.png"
          alt="Utløservariabel i Google Tag Manager-skjermbilde"
          className="article-img"
        />
        <ul>
          <li>
            I den andre nedtrekksmenyen, velg <strong>samsvarer med CSS-velger</strong>.<br />
            Nå skal jeg forklare hva vi gjør:
            <br />
            Vi prøver å generere en klikkutløser for et spesifikt HTML-element på nettstedet ditt - det du vil spore klikk på. I mitt eksempel er det en <strong>knapp</strong>, men det kan også være et <strong>bilde</strong> eller en <code>&lt;div&gt;</code>-tag, eller en hvilken som helst annen HTML-tag - så spørsmålet er: Hvordan forteller vi Google Tag Manager (GTM) hvilket element som skal observeres?
            <br />
            Svaret er: <strong>CSS-velgere</strong> - Du vet ikke hva det er? Det er bare en programmatiske måte å spesifisere HTML-elementer på siden, og jeg vil vise deg en måte å få CSS-velgeren for ethvert element med bare noen få museklikk.
          </li>
          <li>
            I Googles Chrome-nettleser, <strong>høyreklikk</strong> på ethvert element du vil spore klikk på, og velg <strong>inspiser</strong>. Chrome Developer Tools åpnes og som standard vil HTML-elementet være forhåndsvalgt.
          </li>
          <li>
            Deretter høyreklikker du bare på elementet og velger <strong>Kopier</strong> &gt; <strong>Kopier JS-sti</strong> (unngå <em>kopier velger</em> - det returnerer feilaktige velgere fra tid til annen).
          </li>
        </ul>
        <ImgContainerFixed width="529px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-copy-css-selector-for-button-tracking.png"
            alt="Kopier CSS-velger for knappetrykksporing"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Hvis du limer inn innholdet i utklippstavlen i en tekstredigerer, vil du se at du kopierte noe som:
            <br />
            <br />
            <code>document.querySelector(&quot;body &gt; ul &gt; button&quot;)</code>
            <br />
            <br />
            Vi ønsker å ta den delen som er inne i parentesene, uten “”. Så, denne delen:
            <br />
            <br />
            <code>body &gt; ul &gt; button</code>
          </li>
          <li>
            <strong>Dette er CSS-velgeren</strong> vi trenger å lime inn i det siste feltet i vår utløserkonfigurasjon i GTM (<em>se nedenfor</em>).
            <br />
            <strong>Kopier</strong> den verdien og lim den inn. Klikk <strong>lagre</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Hendelsesutløserkonfigurasjon i Google Tag Manager-skjermbilde"
          className="article-img"
        />
        <p>Legg merke til at du kunne gjort dette med ethvert element og satt opp enhver hendelsesutløser i GTM som du vil?</p>
        <p>
          Vi er ikke ferdige ennå. Etter å ha satt opp utløserbetingelsene for vår hendelse, må vi konfigurere Google Analytics-hendelsestaggen for å sikre at de riktige dataene sendes til Google Analytics-eiendommen.
        </p>
        <H as="h3">Opprett en egendefinert hendelsestagg</H>
        <p>
          Nå som vi har en utløser, må vi kombinere den med en faktisk hendelsestagg. Du kan tenke på utløsere og tagger som <em>når</em> og <em>hva</em> i vårt eksempel: <br /> Utløseren definerer <em>når</em> vi vil spore en hendelse (<em>når knappen vår klikkes</em>), og nå vil vi definere en GA-hendelse, som beskriver <em>hva</em> vi gjør: <em>Sende en hendelse til GA inkludert alle de riktige verdiene for hendelsesegenskapene.</em>
        </p>
        <p>La oss komme i gang.</p>
        <ul>
          <li>
            Gå til tag-menyen i Google Tag Manager og <em>opprett en ny tagg</em>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-create-new-tag-screenshot.png"
          alt="Google Tag Manager opprett egendefinert hendelsestagg"
          className="article-img"
        />
        <ul>
          <li>
            Gi taggen et <strong>navn</strong> som <em>Knappehendelse</em> og klikk på <strong>tagkonfigurasjon</strong>.
          </li>
          <li>
            Et panel fra høyre side vil skyve inn med en liste over mulige tagtyper å velge mellom. Velg <strong>Google Analytics - Universal Analytics</strong>.
          </li>
          <li>
            Tilbake i tag-konfigurasjonen, sett <strong>sporingstype</strong> til <strong>hendelse</strong>. Nå vil du se flere felt vises for hendelsesdataene.
          </li>
          <li>
            La oss tildele statiske verdier for <strong>hendelseskategori</strong> og <strong>handling</strong>, og la <strong>hendelsesetiketten</strong> fylles dynamisk. <br />
            Kall <strong>kategori</strong> for eksempel <em>klikkhendelser</em>, <strong>handling</strong> er bare <em>klikk</em> og for <strong>etiketten</strong> klikker du på Lego-kloss-symbolet ved siden av.
          </li>
          <li>
            Vi vil at etiketten alltid skal inneholde teksten som er skrevet på det klikkede HTML-elementet, slik at vi kan skille mellom flere knapper. <br />
            Etter å ha klikket på Lego-klossen, vil et panel gli inn for å <strong>velge en variabel</strong>. Klikk på <strong>innebygde variabler</strong> i øverste høyre hjørne, med mindre du allerede ser <strong>klikktekst</strong> i listen over variabler (da bare <strong>velg det</strong> og hopp over det følgende trinnet).
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-select-built-in-variable-for-event-label.png"
          alt="innebygd variabel for hendelsesetikett i Google Tag Manager"
          className="article-img"
        />
        <ul>
          <li>
            I listen over innebygde variabler, aktiver <strong>klikktekst</strong> og GTM vil legge til den variabelen i etikettfeltet med krølleparenteser, som dette: <code>{"{{Click Text}}"}</code> - Doble krølleparenteser er den interne måten i GTM å referere til variabler på.
          </li>
          <li>
            Feltet <strong>verdi</strong> i tag-konfigurasjonen kan fylles med en verdi hendelsesklikket kan representere. <br />
            I mitt eksempel lar jeg feltet være <strong>tomt</strong> fordi jeg ikke har noen meningsfull pengeverdi for knappetrykket.
          </li>
          <li>
            Hvis hendelsen er <strong>interaktiv</strong>, velg <strong>false</strong> for ikke-interaksjonstreff. Hvis det er en <strong>passiv hendelse</strong>, velg <strong>true</strong>.
          </li>
          <li>
            I variabelen <strong>Google Analytics-innstillinger</strong>, velg variabelen du konfigurerte da du <a href="/no/google-analytics-oppsett">satte opp Google Analytics</a>.
          </li>
          <li>
            Hvis du ikke har konfigurert en <strong>innstillingsvariabel</strong>, sett en hake for <em>Aktiver overstyring av innstillinger i denne taggen</em> og legg til sporings-ID-en (f.eks. <em>UA-12345678-91</em>) fra din <Link to="/no/google-analytics-oppsett">
              Google Analytics sporingskode
            </Link>
            .
          </li>
          <li>
            Til slutt, rull ned til <strong>utløser</strong>-konfigurasjonen, <strong>klikk på den</strong> og velg utløseren vi konfigurerte like før: <em>Click - Button</em>
          </li>
        </ul>
        <p>Hele hendelsetag-konfigurasjonen på en gang:</p>
        <ImgContainerFixed width="371px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-tag-configuration-screenshot.png"
            alt="GTM hendelsetagg-konfigurasjon"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Nå klikker du <strong>lagre</strong> øverst til høyre, og du er ferdig med å sette opp GA-hendelsen.
          </li>
        </ul>
        <p>Flott! Neste steg er å dobbeltsjekke om vår onclick-sporingsoppsett fungerer.</p>
        <H as="h2">Feilsøke hendelser</H>
        <p>
          For å feilsøke oppsettet, gå inn i <strong>forhåndsvisningsmodus</strong> fra oversikten i Google Tag Manager:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-preview.png"
          alt="Google Tag Manager forhåndsvisningsmodus"
          className="article-img"
        />
        <br />
        <p>
          Deretter besøker vi siden som inneholder send-knappen. På grunn av forhåndsvisningsmodus i Google Tag Manager har vi visse <Link to="/no/wiki-analytics/informasjonskapsler">informasjonskapsler</Link> satt i nettleseren vår, som vil åpne et feilsøkingsvindu nederst på siden:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-debugging-mode.png"
          alt="Google Tag Manager feilsøkingsmodus for hendelser"
          className="article-img"
        />
        <br />
        <p>
          Hvis du nå <strong>klikker på knappen</strong> for å teste hendelsessporingen, bør du se et <strong>klikk</strong> vises i venstre oppsummeringspanel, og hovedfeilsøkingsvinduet skal vise at vår hendelse ble utløst:
        </p>
        <ImgContainerFixed width="555px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-button-click.png"
            alt="Google Tag Manager feilsøker klikksporing"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          Hvis du klikker på <strong>knappehendelsen</strong> kan du bekrefte hendelsesfeltene som ble sendt sammen med hendelsestreffet:
        </p>
        <ImgContainerFixed width="496px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-event-properties-for-category-action-label.png"
            alt="Google Tag Manager feilsøker hendelsesfelter"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          Ser du hvordan <strong>hendelsesetiketten</strong> automatisk har blitt fylt ut med "OK"? Det er fordi vi brukte den innebygde variabelen <strong>klikktekst</strong> i Google Tag Manager, som alltid holder teksten til det klikkede elementet.
        </p>
        <p>Viktigere, hvis du ruller lenger ned, kan du bekrefte reglene for hendelsesutløseren:</p>
        <ImgContainerFixed width="413px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-trigger-rules.png"
            alt="Google Tag Manager hendelsesutløserregler"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          <strong>TIPS</strong>: For å bekrefte at Google Analytics-kontoen din mottok alle dataene, kan vi besøke <strong>sanntidsrapporten for hendelser</strong> i Google Analytics. Hvis det har gått noen minutter siden siste knappetrykk, bør <strong>visningen for de siste 30 minuttene</strong> hjelpe.
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-realtime-report.png"
          alt="Google Analytics sanntidsrapport for hendelser"
          className="article-img"
        />
        <H as="h3">Validering av CSS-velger</H>
        <p>
          Hvis feilsøkeren ikke oppdaget knappetrykkene dine og ikke viste noen klikkhendelse, er det sannsynligvis noe galt med <strong>CSS-velgeren</strong>.<br />
          <strong>TIPS:</strong> Du kan åpne <strong>Chrome Developer Tools</strong> på siden med elementet du vil spore ved å enten trykke <strong>F12</strong> eller <strong>høyreklikke</strong> &gt; <strong>inspisere</strong> og besøke <strong>elementer</strong>-fanen. Hvis du nå trykker <strong>CTRL+F</strong> eller <strong>CMD+F</strong> på Mac for å søke etter et element, kan du ganske enkelt <strong>lime inn CSS-velgeren din i søkefeltet</strong> og utviklerverktøyene vil automatisk hoppe til elementet:
        </p>
        <ImgContainerFixed width="578px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-find-by-css-selector.png"
            alt="Google utviklerverktøy finner element med CSS-velger"
            className="article-img"
          />
        </ImgContainerFixed>
        <p>
          På denne måten kan du bekrefte om CSS-velgeren din fungerer. Hvis utviklerverktøyene ikke hopper til noe element i koden, er det noe galt med velgeren. Det finnes også <strong>nettleserutvidelser</strong> som kan hjelpe deg med å finne riktig velger.
        </p>
        <p>
          Etter feilsøking og fiksing av velgere bør vi endelig ha klikksporing i gang og kan gå tilbake til Google Tag Manager for å <strong>forlate forhåndsvisningsmodus</strong> og <strong>publisere endringene</strong>.
        </p>
        <p>
          Gratulerer så langt! Du har konfigurert hendelsesovervåking for å klikke på en knapp basert på en CSS-velger!
        </p>
        <p>
          Med oppsettet som nå fungerer, kan du <Link to="/no/google-analytics-oppsett">sette opp hendelsesmål</Link> i GA! Google Analytics-mål er en nyttig funksjon, da du kan dele rapporter og se hvor mange konverteringer som har skjedd for et gitt undersett.
        </p>
        <p>
          For å sette det opp, må du ganske enkelt definere et egendefinert Google Analytics-mål med kategori-, handlings- og etikettfeltene som samsvarer med hendelsen din, og da vil det bli telt som et hendelsesmål.
        </p>
        <H as="h2">Fordeler og ulemper ved sporing med CSS-velgere</H>
        <p>
          Det er viktig å nevne at å sette opp hendelsessporing basert på CSS-velgere ikke er den mest robuste metoden.
        </p>
        <p>
          Hele hendelsesoppsettet vil bryte hvis CSS-velgeren ikke fungerer. Det er en <em>kritisk avhengighet</em>, som representerer en svakhet i denne tilnærmingen til å sette det opp.
        </p>
        <p>Men hvorfor skulle velgeren noen gang slutte å fungere?</p>
        <p>
          Jo større nettstedet er, desto flere endringer skjer på nettstedet, og jo flere personer er involvert - muligens samtidig i forskjellige team.
          <br /> Så snart en endring påvirker strukturen til vår velger, vil den slutte å fungere.
        </p>
        <p>
          En frontend-utvikler kan godt legge til noen endringer på siden en dag i fremtiden, noe som kan føre til at velgeren slutter å fungere. Derfor ville det være mer robust å legge til en <strong>onclick-hendelseshåndterer</strong> til elementet, som skyver alle hendelsesegenskapene til et <strong>
            <Link to="/no/data-lag">data lag</Link>
          </strong>. Dette er den anbefalte tilnærmingen for viktige KPI-er og for store organisasjoner, der endringer ikke overvåkes.
        </p>
        <p>
          Avanserte webanalyseimplementeringer som integrerer databaser og tredjeparts datakilder bruker et <Link to="/no/data-lag">data lag for å spore hendelser</Link>. Vennligst les den artikkelen for den nødvendige hendelsessporingskoden.
        </p>
        <p>
          Å bruke et data lag er mer sikkert og pålitelig, men krever en grundig implementering.
        </p>
        <p>
          I stedet for å stole på CSS-velgere, bruker du hendelselyttere for <a href="https://developer.mozilla.org/en-US/docs/Web/Events" target="_blank">nettleserhendelser</a> som en utløser. GTM bruker også klikkhendelseslyttere under panseret når du setter opp en klikkutløser, men når du baserer sporing på et data lag kan du mer fritt velge hvilke nettleserhendelser som skal spores.
        </p>
        <p>
          Spesielt for en enkeltside-app (SPA) uten sidelaster mellom sidenavigasjon, kan et data lag brukes til å spore <b>virtuelle sidevisninger</b> som hendelser og få dem til å telle som vanlige sidevisninger i Google Analytics.
        </p>
        <p>
          For mindre nettsteder er det ikke alltid berettiget innsatsen med å implementere et data lag. I slike tilfeller er hendelsessporing basert på CSS-velgere en gyldig tilnærming.
        </p>
        <p>
          <strong>Fordelen</strong> med å bruke CSS-velgere er klart <strong>enkelheten</strong> ved implementering og hvor <strong>raskt</strong> du kan begynne å samle data. Dette kan også være nyttig hvis en data lag-implementering kan ta en stund på grunn av mangel på ressurser, fordi du kan være i stand til allerede å samle inn noen av dataene bare med CSS-velgere.
        </p>
        <p>
          <strong>Ulempen</strong> er at denne metoden er nesten <strong>utsatt for å bryte</strong> en dag, avhengig av hvor mange endringer som implementeres og hvor mange team som jobber på nettstedet.
        </p>
        <p>
          Men hvis du setter opp nettsporing for ditt eget nettsted og du har kontroll over endringene, kan du gjerne følge denne metoden. Bare vær klar over at endringer i sidens HTML-struktur kan ødelegge CSS-velgeren.
        </p>
        <p>
          Imidlertid bør selskaper med budsjett og høye krav til datakvalitet velge et data lag som grunnlag for deres hendelsessporingsoppsett.
        </p>
        <H as="h2">Hendelsesrapporter i Google Analytics</H>
        <p>
          Nå som vi har satt opp sporing for brukerinteraksjoner, må vi sørge for at vi følger med på mengden hendelser som sendes til Google Analytics-kontoen.
        </p>
        <p>
          Den beste måten å gjøre dette på er enten med den innebygde <strong>hendelsesrapporten</strong>, med en <strong>egendefinert rapport</strong> eller et Google Analytics <strong>dashboard</strong>.
        </p>
        <p>
          I <strong>Google Analytics</strong> kan du gå til <strong>Atferd</strong> &gt; <strong>Hendelser</strong> &gt; <strong>Topp hendelser</strong> for å finne hendelsesdataene du sporet:
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report.png"
          alt="Google Analytics hendelsessporingsrapport oversikt"
          className="article-img"
        />
        <br />
        <p>
          Siden det ikke er noe standard <b>hendelsesnavn</b> tilgjengelig i GA, vil du først se hendelseskategoriene dine. Du kan også velge hoveddimensjonen til å være <strong>hendelseshandling</strong> eller <strong>hendelsesetikett</strong> for å gå dypere inn i rapporten.
        </p>
        <p>
          Vær oppmerksom på at hendelsesrapporten gir oss en kolonne med <strong>Gj.sn. verdi</strong>, som kommer godt med hvis vi trenger gjennomsnittsverdien av våre klikkhendelser. Dette kan være relevant for en webapp som beregner priser, der det endelige resultatet spores med en hendelse. På denne måten kan du enkelt finne gj.sn. verdien av en brukers beregninger.
        </p>
        <p>
          Andre vanlige eksempler på nyttige gjennomsnittsverdier er f.eks. filterinnstillinger for priser eller størrelser i en nettbutikk.
        </p>
        <p>
          Så husk at en <strong>hendelsesverdi</strong> kan virke irrelevant i begynnelsen, men det kan godt være en relatert verdi å tildele til hendelsen uansett, siden det vil være nyttig å sjekke gjennomsnittsverdier over hele linjen.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default EventTracking;

